exports.collectiveExhibits = [
    {
        "date": "2018",
        "name": "Pelos Rios de Água doce",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2018",
        "name": "Amazonas Plural",
        "place": "Usina Chaminé",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2017",
        "name": "Circuito de Artes Visuais",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2016",
        "name": "1º Salão Pan-Amazonico de Artes",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2016",
        "name": "Art ICBEU 60th - Artistas Contemporâneos",
        "place": "ICBEU",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2015",
        "name": "Exposição Coletiva",
        "place": "Galerie l'amazonie",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2014",
        "name": "Mostra Artisti Brasiliani",
        "place": "Galleria Della Pigna",
        "city": "Roma",
        "state": "Lazio",
        "country": "Itália"
    },
    {
        "date": "2014",
        "name": "Amostra Internacional de Artesanato",
        "place": "Galleria Della Pigna",
        "city": "Florença",
        "state": "Toscana",
        "country": "Itália"
    },
    {
        "date": "2014",
        "name": "5º Salão de Artes Plásticas ALEAM",
        "place": "ALEAM",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2013",
        "name": "1ª Amostra de Artes Plásticas do Shopping Ponta Negra",
        "place": "Shopping Ponta Negra",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2013",
        "name": "1ª Mostra Pan-Amazônica de Artes",
        "place": "Studio 5",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2013",
        "name": "61º Salão de Artes Plásticas do Clube Militar",
        "place": "Clube Militar",
        "city": "Rio de Janeiro",
        "state": "RJ",
        "country": "Brasil"
    },
    {
        "date": "2013",
        "name": "Exposição Amazônia",
        "place": "Clube Militar",
        "city": "Rio de Janeiro",
        "state": "RJ",
        "country": "Brasil"
    },
    {
        "date": "2013",
        "name": "Festival Amazônia",
        "place": "Clube Militar",
        "city": "Faro",
        "state": "Algarve",
        "country": "Portugal"
    },
    {
        "date": "2013",
        "name": "4º Salão de Artes Plásticas ALEAM",
        "place": "ALEAM",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2012",
        "name": "Arte Contemporânea do Amazonas - Exposição itinerante",
        "city": "Recife, Brasília e Fortaleza",
        "state": "AM, DF e CE",
        "country": "Brasil"
    },
    {
        "date": "2012",
        "name": "60º Salão de Artes Plásticas do Clube Militar",
        "place": "Clube Militar",
        "city": "Rio de Janeiro",
        "state": "RJ",
        "country": "Brasil"
    },
    {
        "date": "2012",
        "name": "Dos lápis de Di ao festim das barrancas - Pré-Bienal de Artes",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2012",
        "name": "I Jornada Interdisciplinar do Martha Falcão",
        "place": "Faculdade Martha Falcão",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2012",
        "name": "Amazônia - Paiol da Cultura",
        "place": "INPA",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2012",
        "name": "3º Salão de Artes Plásticas ALEAM",
        "place": "ALEAM",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2011",
        "name": "Exposição Amazônia",
        "place": "Museu histórico do Exército e Forte de Copacabana",
        "city": "Rio de Janeiro",
        "state": "RJ",
        "country": "Brasil"
    },
    {
        "date": "2009",
        "name": "X Salão Manaus - Marinha do Brasil",
        "city": "Rio de Janeiro",
        "state": "RJ",
        "country": "Brasil"
    },
    {
        "date": "2008",
        "name": "Comemoração aos 10 anos do Palácio das Artes",
        "place": "Galeria Palácio das Artes",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2007",
        "name": "VIII Salão Manaus - Marinha do Brasil",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2007",
        "name": "8° Salão Curupira de Artes Plásticas",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2006",
        "name": "VII Salão Manaus – Marinha do Brasil",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2006",
        "name": "I Salão Tucuxi de Pintura",
        "place": "Galeria Palácio das Artes",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2005",
        "name": "VI Salão Manaus – Marinha do Brasil",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2004",
        "name": "Igarapés de Manaus",
        "place": "Galeria Palácio das Artes",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2004",
        "name": "5° Salão Curupira de Artes Plásticas",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2002",
        "name": "III Salão Manaus – Marinha do Brasil",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2001",
        "name": "II Salão Manaus – Marinha do Brasil",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2001",
        "name": "II Salão Manaus – Marinha do Brasil",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2000",
        "name": "I Salão Manaus – Marinha do Brasil",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1999",
        "name": "Comemoração do 30° aniv. do 12° Batalhão de Suprimentos",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1998",
        "name": "Centenário Teatro Amazonas - I Salão Internacional de Artes Plásticas",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1996",
        "name": "Branco e Silva - I Salão Internacional de Artes Plásticas do Amazonas",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1996",
        "name": "I Mostra SENAC de Artes Plásticas",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1996",
        "place": "Rio Negro Center",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1996",
        "name": "Exposição Itinerante Internacional",
        "city": "Roma, Cremona, Montalcino, Castel Goffredo, Grosseto, Spello e Venturine",
        "country": "Itália"
    },
    {
        "date": "1995",
        "name": "I feira Regional de Turismo e Artesanato",
        "place": "SEBRAE",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1995",
        "place": "Caixa Econômica Federal",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1994",
        "name": "I Feira de Artes do Estado do Amazonas",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1993",
        "name": "Exposição do Liceu de Artes e Ofício Cláudio Santoro",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1993",
        "name": "I FINCATA",
        "city": "Tabatinga",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1993",
        "name": "Comemoração do dia internacional do artista",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1993",
        "name": "Simpósio Internacional de Secretariado",
        "place": "SEBRAE",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1993",
        "name": "Cultura Amazônica",
        "place": "Iate Clube",
        "city": "Brasília",
        "state": "DF",
        "country": "Brasil"
    },
    {
        "date": "1993",
        "name": "Encontro Artístico - Semana do Exército",
        "city": "Brasília",
        "state": "DF",
        "country": "Brasil"
    },
    {
        "date": "1993",
        "name": "Arte Amazônica",
        "place": "Sede Nacional do SEBRAE",
        "city": "Brasília",
        "state": "DF",
        "country": "Brasil"
    },
    {
        "date": "1993",
        "place": "Hotel Hilton",
        "city": "Belém",
        "state": "PA",
        "country": "Brasil"
    },
    {
        "date": "1991",
        "name": "Galeria Di Irene",
        "place": "Plaza Hotel",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1991",
        "name": "Galeria Di Irene",
        "place": "Imperial Hotel",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1991",
        "name": "Galeria Di Irene",
        "place": "Taj Mahal Hotel",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
];