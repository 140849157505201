<script>
import { mdiMagnify } from "@mdi/js";
const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
export default {
  props: {
    src: {
      default: "",
    },
    imageStyle: {
      default: null,
    },
    zoomSrc: {
      type: String,
      default: null,
    },
    zoom: {
      default: 2.0,
    },
  },
  data() {
    return {
      panelWidth: 250,
      panelHeight: 250,
      panelPosition: [0, 0],
      showPanel: false,
      containerPosition: [0, 0],
      containerSize: [0, 0],
      imageSize: [0, 0],
      zoomIcon: mdiMagnify,
    };
  },
  methods: {
    updatePanelPosition(event) {
      this.panelPosition = [
        clamp(
          event.clientX,
          this.containerPosition[0] + this.panelWidth / 2,
          this.containerPosition[0] + this.containerSize[0] - this.panelWidth / 2
        ) - this.containerPosition[0],
        clamp(
          event.clientY,
          this.containerPosition[1] + this.panelHeight / 2,
          this.containerPosition[1] + this.containerSize[1] - this.panelHeight / 2
        ) - this.containerPosition[1],
      ];
    },
    showZoom(event) {
      this.updateImageSize();
      this.showPanel = true;
      this.panelPosition = [
        event.clientX - this.containerPosition[0],
        event.clientY - this.containerPosition[1],
      ];
    },
    updateImageSize() {
      const imageRect = this.$refs.image.getBoundingClientRect();
      this.imageSize = [imageRect.width * this.zoom, imageRect.height * this.zoom];
      this.containerSize = [imageRect.width, imageRect.height];
    },
  },
  updated() {
    const rect = this.$refs.container.getBoundingClientRect();
    this.containerPosition = [rect.left, rect.top];
  },
  mounted() {
    this.updateImageSize();
  },
  computed: {
    imagePosition() {
      const dx = this.containerSize[0] * (this.zoom - 1);
      const dy = this.containerSize[1] * (this.zoom - 1);
      const x = this.panelPosition[0] - this.panelWidth / 2;
      const y = this.panelPosition[1] - this.panelHeight / 2;
      const w = this.containerSize[0] - this.panelWidth;
      const h = this.containerSize[1] - this.panelHeight;
      const ratio = [x / w, y / h];
      const res = [-dx * ratio[0], -dy * ratio[1]];
      return res;
    },
  },
};
</script>

<template>
  <div
    class="relative"
    @mouseenter="showZoom"
    @pointerenter="showZoom"
    @mouseleave="showPanel = false"
    @pointerleave="showPanel = false"
    @mousemove="updatePanelPosition"
    @pointermove="updatePanelPosition"
    ref="container"
    data-cy="container"
  >
    <img :src="src" ref="image" :style="imageStyle" data-cy="base-image" />
    <div class="absolute w-full bottom-4 text-gray-200 flex">
      <div
        class="
          px-2
          py-1
          rounded-lg
          mx-auto
          flex
          items-center
          gap-1
          font-body font-light
        "
        :style="{
          'background-color': '#00000088',
        }"
        v-show="!showPanel"
      >
        <svg-icon :path="zoomIcon" type="mdi" class="text-gray-400 w-4 h-4"></svg-icon>
        Sobrevoe o mouse para ampliar
      </div>
    </div>
    <div class="absolute inset-0 bg-white overflow-hidden" v-show="showPanel">
      <img
        :src="zoomSrc ? zoomSrc : src"
        :style="{
          'transform-origin': 'top left',
          'margin-left': `${imagePosition[0]}px`,
          'margin-top': `${imagePosition[1]}px`,
          transform: `scale(${this.zoom})`,
        }"
        data-cy="zoomed-image"
      />
      <div
        class="absolute border-gray-300 border-2 rounded-sm opacity-50"
        :style="{
          width: panelWidth + 'px',
          height: panelHeight + 'px',
          left: panelPosition[0] - panelWidth / 2 + 'px',
          top: panelPosition[1] - panelHeight / 2 + 'px',
        }"
      ></div>
    </div>
  </div>
</template>
