export function bubblePartition(groupWeight, sourceArray, weightAccessor) {
  const w = weightAccessor;

  function elemIndexThatFits(array, weight, exactFit = true) {
    const exact = array.findIndex((obj) => w(obj) == weight);
    if (exactFit || exact !== -1) return exact;
    return array.indexOf(array.reduce((a, b) => (w(b) > w(a) && w(b) < weight) ? b : a));
  }

  const pool = [...sourceArray];
  const groups = [];
  var group = [];
  var weight = 0;

  while (pool.length > 0) {
    var leftover = groupWeight - weight;

    while (weight < groupWeight && pool.length > 0) {
      if (w(pool[0]) > leftover) break;

      const elem = pool.shift();
      group.push(elem);
      weight += w(elem);
      leftover = groupWeight - weight;
    }

    var testGroup = [...group];
    var lastTestedWeight = 0;
    var testWeight = weight;
    var discarded = null;

    while (leftover !== 0 && pool.length > 0) {
      const bubIndex = elemIndexThatFits(pool, leftover);
      if (bubIndex !== -1) {
        var bubble = pool.splice(bubIndex, 1)[0];
        testGroup.push(bubble);
        testWeight += w(bubble);
        leftover = 0;
        if (discarded) {
          pool.splice(0, 0, discarded);
        }
      } else {
        testGroup = [...group];
        testWeight = weight;
        leftover = groupWeight - weight;

        var discardable = [...testGroup].filter((i) => w(i) > lastTestedWeight);
        if (discardable.length === 0) break;

        const discIndex = testGroup.indexOf(discardable.sort((a,b) => w(a) - w(b))[0]);
        discarded = testGroup.splice(discIndex, 1)[0];
        testWeight -= w(discarded);
        lastTestedWeight = w(discarded);
        leftover = groupWeight - testWeight;
      }
    }

    groups.push(testGroup);
    group = [];
    weight = 0;
  }

  return groups;
}