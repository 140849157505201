<template>
  <div class="lg:mr-4 px-6 md:px-8">
    <div class="max-w-gallery mx-auto mt-16 font-head mb-28">
      <h1
        class="uppercase text-2xl font-medium text-gray-900 leading-6 tracking-wide"
      >
        Explorar Galeria
      </h1>

      <div v-if="series.length === 0" class="mt-8 grid grid-cols-3 gap-8">
        <skeleton
          class="h-52 rounded-md bg-gray-300"
          v-for="(x, i) in Array(6)"
          :key="i"
          :showContent="false"
        ></skeleton>
      </div>

      <nav v-else class="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        <router-link
          class="relative overflow-hidden rounded-md h-52"
          :to="{ name: 'gallery-series', params: { series: series.slug } }"
          v-for="(series, i) in series"
          :key="i"
        >
          <div
            class="absolute inset-0 w-full h-full bg-no-repeat bg-center"
            :class="{ 'bg-cover': !series.capa_escala }"
            :style="{
              'background-image': `url(${apiUrl}/images/${series.capa_caminho})`,
              'background-size': series.capa_escala
                ? (100 * series.capa_escala).toString() + '%'
                : '',
            }"
          ></div>
          <div
            class="relative h-full flex bg-gradient-to-t from-gray-900 to-transparent px-6 py-3"
          >
            <span class="text-lg text-white font-bold tracking-wide mt-auto">{{
              series.nome
            }}</span>
          </div>
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const allLink = {
  nome: "Todas as obras",
  slug: "all",
  capa_caminho: "work/anoitecer-sensual_thumbnail.jpg",
};

export default {
  data() {
    return {
      title: "Mandalas de Francimar Barbosa",
      series: [],
    };
  },
  created() {
    axios.get(`${this.apiUrl}/series`).then((res) => {
      this.series = [...res.data, allLink];
    });
  },
};
</script>