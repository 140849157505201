<template>
  <div class="px-6 md:px-8 lg:px-11 lg:mr-4 mb-10">
    <div class="max-w-gallery mx-auto">
      <router-link
        class="mt-6 inline-flex items-center text-gray-700 font-head"
        :to="{
          name: 'gallery',
          params: {
            series: $store.state.galleryParams.series.slug,
            page: $store.state.galleryParams.page,
          },
        }"
        v-if="$store.state.galleryParams"
      >
        <svg-icon
          :path="icons.back"
          type="mdi"
          class="text-gray-500 w-5 h-5"
        ></svg-icon>
        <span class="ml-1">{{ $store.state.galleryParams.series.nome }}</span>
      </router-link>

      <div
        class="
          flex flex-col
          md:flex-row
          max-w-gallery
          mt-2
          md:mt-6
          lg:mt-2
          gap-0
          md:gap-4
          lg:gap-10
        "
      >
        <div
          class="-mx-6 lg:-mx-11 flex-shrink-0"
          :style="{
            width: breakpoints.lg ? '62.8%' : breakpoints.md ? '70%' : 'auto',
          }"
        >
          <div
            :class="{
              'painting-container': breakpoints.lg,
              'h-full': !breakpoints.lg,
            }"
            class="flex text-gray-300"
          >
            <div class="flex">
              <div
                class="w-11"
                v-if="
                  !work ||
                  (!work.prev_slug &&
                    !$store.getters.previousWork &&
                    $store.getters.isFirstPage)
                "
              ></div>
              <router-link
                v-if="
                  work &&
                  (work.prev_slug ||
                    $store.getters.previousWork ||
                    !$store.getters.isFirstPage)
                "
                class="my-auto hover:text-gray-400"
                :to="
                  work.prev_slug || $store.getters.previousWork
                    ? {
                        name: 'work',
                        params: {
                          slug: $store.getters.previousWork
                            ? $store.getters.previousWork.slug
                            : work.prev_slug,
                        },
                      }
                    : {
                        name: 'gallery',
                        params: {
                          series: $store.state.galleryParams.series.slug,
                          page: $store.state.galleryParams.page - 1,
                        },
                      }
                "
              >
                <svg-icon
                  class="w-11 h-11"
                  type="mdi"
                  :class="{
                    'opacity-0': !(
                      work.prev_slug ||
                      $store.getters.previousWork ||
                      !$store.getters.isFirstPage
                    ),
                  }"
                  :path="icons.left"
                ></svg-icon>
              </router-link>
            </div>

            <aspect-ratio
              class="flex-grow h-full"
              :disabled="!expandWorkContainer"
            >
              <skeleton class="h-full w-full" :showContent="work">
                <template #default>
                  <async-image
                    class="w-full h-full flex"
                    imageClass="m-auto max-w-full shadow-lg"
                    :imageStyle="{
                      'max-height': breakpoints.md ? '100%' : '30rem',
                    }"
                    :src="`${apiUrl}/images/work/${work.slug}_viewport.jpg`"
                    :alt="`Foto da obra ${work.titulo}`"
                    @error="expandWorkContainer = true"
                    @load="expandWorkContainer = false"
                  />
                </template>
              </skeleton>
            </aspect-ratio>

            <div class="flex">
              <div
                class="w-11"
                v-if="
                  !work ||
                  (!work.next_slug &&
                    !$store.getters.nextWork &&
                    $store.getters.isLastPage)
                "
              ></div>
              <router-link
                class="my-auto hover:text-gray-400"
                v-if="
                  work &&
                  (work.next_slug ||
                    $store.getters.nextWork ||
                    !$store.getters.isLastPage)
                "
                :to="
                  work.next_slug || $store.getters.nextWork
                    ? {
                        name: 'work',
                        params: {
                          slug: $store.getters.nextWork
                            ? $store.getters.nextWork.slug
                            : work.next_slug,
                        },
                      }
                    : {
                        name: 'gallery',
                        params: {
                          series: $store.state.galleryParams.series.slug,
                          page: $store.state.galleryParams.page + 1,
                        },
                      }
                "
              >
                <svg-icon
                  class="w-11 h-11"
                  type="mdi"
                  :class="{
                    'opacity-0': !(
                      work.next_slug ||
                      $store.getters.nextWork ||
                      !$store.getters.isLastPage
                    ),
                  }"
                  :path="icons.right"
                ></svg-icon>
              </router-link>
            </div>
          </div>
        </div>

        <div class="flex flex-col flex-grow px-5 md:px-0 md:ml-10 mt-8 md:mt-0">
          <section class="my-auto">
            <skeleton class="text-4xl flex" :showContent="work">
              <template #default>
                <h1 class="text-gray-900 font-head">
                  {{ work.titulo }}
                </h1>
              </template>
            </skeleton>

            <div>
              <hr class="mt-2" />

              <skeleton
                class="mt-1 text-gray-600 font-head"
                :showContent="work"
              >
                <template #default>
                  <span>{{ work.serie_nome }}</span>
                  <span v-if="work.data">
                    <span class="text-gray-500"> • </span>
                    {{ work.data | dateYear }}</span
                  >
                </template>
              </skeleton>

              <div
                v-if="work"
                class="mt-6 flex flex-col gap-2.5 items-start text-gray-600"
              >
                <div
                  class="flex gap-2.5 items-center text-gray-600"
                  :class="{ 'font-medium': work.preco }"
                  v-tooltip.right="{ content: 'Preço', classes: 'ml-4' }"
                >
                  <div
                    class="rounded-full bg-green-300 text-white w-10 h-10 flex"
                  >
                    <svg-icon
                      class="m-auto w-6 h-6"
                      type="mdi"
                      :path="icons.price"
                    ></svg-icon>
                  </div>
                  {{
                    work.preco
                      ? "R$ " +
                        work.preco.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                        })
                      : "Indisponível"
                  }}
                </div>
                <div
                  class="flex gap-2.5 items-center tracking-wide"
                  v-tooltip.right="{ content: 'Técnica', classes: 'ml-4' }"
                >
                  <div
                    class="
                      rounded-full
                      bg-green-100
                      w-10
                      h-10
                      flex
                      text-gray-600
                    "
                  >
                    <svg-icon
                      class="m-auto"
                      width="20"
                      height="20"
                      type="mdi"
                      :path="icons.technique"
                    ></svg-icon>
                  </div>
                  {{ work.tecnica }}
                </div>
                <div
                  class="flex gap-2.5 items-center tracking-wide"
                  v-tooltip.right="{ content: 'Dimensões', classes: 'ml-4' }"
                >
                  <div
                    class="
                      rounded-full
                      bg-green-100
                      w-10
                      h-10
                      flex
                      text-gray-600
                    "
                  >
                    <svg-icon
                      class="m-auto"
                      width="18"
                      height="18"
                      type="mdi"
                      :path="icons.dimensions"
                    ></svg-icon>
                  </div>
                  {{ work.largura | dimension }} x {{ work.altura | dimension }}
                </div>
              </div>

              <skeleton
                :showContent="work"
                skelClass="h-44"
                class="
                  text-gray-500
                  mt-6
                  flex flex-col
                  items-stretch
                  lg:items-start
                "
              >
                <template #default>
                  <hr class="w-full mb-4" />
                  <button
                    @click="modalOpen = true"
                    class="
                      rounded
                      py-2
                      px-2
                      border
                      hover:border-gray-300 hover:text-gray-600
                      text-sm
                      leading-none
                      flex
                      gap-1
                      items-center
                      justify-center
                    "
                  >
                    <svg-icon
                      class="w-4 h-4"
                      type="mdi"
                      :path="icons.fullscreen"
                    ></svg-icon>
                    Ver em tela cheia
                  </button>
                  <div
                    class="
                      mt-4
                      rounded
                      bg-gray-100
                      py-2
                      text-sm
                      px-2
                      flex
                      items-center
                      justify-center
                      gap-1
                    "
                    v-if="work.preco"
                  >
                    <svg-icon
                      class="w-4 h-4"
                      type="mdi"
                      :path="icons.info"
                    ></svg-icon>
                    <span>
                      Tem interesse?
                      <span class="text-blue-400">
                        <router-link :to="{ name: 'contact' }" target="_blank"
                          >Contate o artista!</router-link
                        ></span
                      >
                    </span>
                  </div>
                </template>
              </skeleton>
            </div>
          </section>
        </div>
      </div>

      <div
        class="mt-12 px-5 lg:px-0 md:mt-20 mb-32 text-gray-700"
        v-if="work && work.descricao"
      >
        <h2 class="font-head uppercase font-medium tracking-wider text-2xl">
          Sobre
        </h2>

        <p class="mt-5 tracking-wide font-light text-lg leading-9">
          <vue-markdown class="markdown-container">{{work.descricao}}</vue-markdown>
        </p>
      </div>
    </div>

    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
      modalClass="modal-dark p-0 relative overflow-hidden m-auto"
      :modalStyle="{
        'max-width': 'none',
      }"
      closeButtonClass="float-none absolute right-3 top-3 z-10"
      v-if="work"
    >
      <div class="bg-white p-2">
        <zoom-image
          class="rounded-sm overflow-hidden"
          :src="`${apiUrl}/images/work/${work.slug}_fullscreen.jpg`"
          :imageStyle="{
            'max-height': '90vh',
            'max-width': '90vw',
            'min-width': '300px',
            'min-height': '300px',
          }"
          zoom="1.5"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiMenuDown,
  mdiImage,
  mdiBrush,
  mdiViewGridOutline,
  mdiImageBrokenVariant,
  mdiArrowLeft,
  mdiCurrencyUsd,
  mdiOpenInNew,
  mdiInformationOutline,
  mdiFullscreen,
} from "@mdi/js";

import Modal from "./Modal.vue";

export default {
  name: "Work",
  props: {},
  components: {
    Modal,
  },
  data() {
    return {
      icons: {
        left: mdiChevronLeft,
        right: mdiChevronRight,
        menu: mdiMenuDown,
        dimensions: mdiImage,
        technique: mdiBrush,
        grid: mdiViewGridOutline,
        broken: mdiImageBrokenVariant,
        price: mdiCurrencyUsd,
        back: mdiArrowLeft,
        link: mdiOpenInNew,
        info: mdiInformationOutline,
        fullscreen: mdiFullscreen,
      },
      showDetails: false,
      work: null,
      expandWorkContainer: true,
      title: "Obra de Francimar Barbosa",
      modalOpen: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    toggleDetails($event) {
      this.showDetails = !this.showDetails;
      if (!this.showDetails) {
        $event.target.blur();
      }
    },
    fetchData() {
      this.work = null;
      this.expandWorkContainer = true;
      this.$store.dispatch("loadWork", this.$route.params.slug).then(
        (res) => {
          this.work = res;
          this.title = `${this.work.titulo} - ${this.work.serie_nome}`;
        },
        (err) => {
          if (err.response && err.response.status === 404) {
            this.$router.replace({ name: "gallery" });
          }
        }
      );
    },
  },
  filters: {
    dimension(val) {
      var text = Number(val).toFixed(2).toString();
      text =
        text.substr(text.length - 2, 2) === "00"
          ? Math.floor(Number(val))
          : text;
      return text + "m";
    },
    dateYear(val) {
      return new Date(val).getUTCFullYear();
    },
  },
  watch: {
    $route(to, from) {
      if (to.name === from.name) {
        this.fetchData();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.painting-container {
  height: calc(100vh - 10rem);
}

.markdown-container {
  h1 {
    @apply font-head text-4xl font-normal -mt-4 mb-5;
  }

  h2 {
    @apply font-head text-3xl font-normal mb-4;
  }

  h3 {
    @apply font-head text-2xl font-normal text-black mb-4;
  }

  p {
    @apply mb-8;
  }
}
</style>
