import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import SvgIcon from "@jamescoyle/vue-icon";
import titleMixin from "./mixins/titleMixin";
import VTooltip from "v-tooltip";
import ZoomImage from "./components/ZoomImage";
import AsyncImage from "./components/AsyncImage";
import Skeleton from "./components/Skeleton";
import AspectRatio from "./components/AspectRatio";
import PortalVue from "portal-vue";
import VueMarkdown from "vue-markdown-v2";

import "./index.css";
import responsiveMixin from "./mixins/responsiveMixin";
import apiMixin from "./mixins/apiMixin";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VTooltip);
Vue.use(PortalVue);
Vue.mixin(titleMixin);
Vue.mixin(responsiveMixin);
Vue.mixin(apiMixin);
Vue.component("vue-markdown", VueMarkdown);
Vue.component("svg-icon", SvgIcon);
Vue.component("async-image", AsyncImage);
Vue.component("zoom-image", ZoomImage);
Vue.component("skeleton", Skeleton);
Vue.component("aspect-ratio", AspectRatio);
