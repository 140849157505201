<template>
  <div class="text-gray-900 mb-20">
    <div id="artist-banner" class="h-72 overflow-hidden relative">
      <div
        class="bg-center bg-repeat absolute -inset-x-4 -inset-y-4"
        style="
          -webkit-filter: grayscale(0.5) saturate(0.8) blur(2px);
          filter: grayscale(0.5) saturate(0.8) blur(2px);
        "
        :style="{
          backgroundImage: `url(${require('@/assets/images/artist-wall.png')})`,
          backgroundSize: breakpoints.sm ? '70%' : 'cover',
        }"
      ></div>
    </div>

    <div class="flex-col px-8">
      <div
        class="flex mx-auto leading-tight shadow max-w-artist-card relative gap-x-10 mb-12 text-gray-700 bg-white -mt-52 flex-col items-center px-8 py-6 sm:flex-row sm:-mt-39 sm:mb-20 sm:pl-12 sm:pt-9 sm:pb-8 sm:pr-7"
      >
        <div
          class="w-47 h-47 rounded-full sm:h-61 sm:rounded-none overflow-hidden"
        >
          <img
            class="-mt-8 sm:-mt-10 object-cover object-left"
            style="height: 118%"
            :src="require('@/assets/images/artist-profile.png')"
          />
        </div>

        <section class="flex flex-col flex-grow w-full sm:w-auto">
          <span class="mt-2 font-light leading-none">Conheça</span>
          <h1 class="text-5xl font-semibold text-gray-700 tracking-semiwide">
            Francimar Barbosa
          </h1>
          <span class="text-1.5xl text-gray-600 mt-1">Artista plástico</span>
          <hr class="mt-2.5 border-t-2" />
          <span class="mt-3 font-light">Membro da AMAP e da ACL/AM</span>

          <div class="mt-7 space-x-1">
            <span class="text-gray-600">Naturalidade: </span>
            <span class="font-light">Manaus, Amazonas</span>
          </div>

          <div class="mt-3 space-x-1">
            <span class="text-gray-600">Idade: </span>
            <span class="font-light">57</span>
          </div>
        </section>
      </div>

      <div class="grid" style="grid-template-columns: 1fr minmax(0,auto) 1fr">
        <section class="col-start-2 sm:col-start-1 flex flex-col mb-12">
          <div class="sticky top-6 sm:ml-auto">
            <h1
              class="font-head uppercase tracking-wider text-gray-500 text-lg font-medium leading-tight mr-14"
            >
              Índice
            </h1>
            <hr class="mt-2.5" />
            <nav
              class="mt-3 flex flex-col space-y-2 leading-loose text-lg text-gray-600"
            >
              <router-link :to="{ hash: '#obra' }">Obra</router-link>
              <router-link :to="{ hash: '#biografia' }">Biografia</router-link>
              <router-link :to="{ hash: '#exposicoes' }"
                >Exposições</router-link
              >
              <router-link :to="{ hash: '#premiacoes' }"
                >Premiações</router-link
              >
              <div class="pt-2">
                <router-link
                  :to="{ name: 'artist' }"
                  @click.native="scrollToTop"
                  class="flex gap-1 items-center"
                >
                  <svg-icon
                    class="w-4 h-4 text-gray-400"
                    type="mdi"
                    :path="icons.top"
                  ></svg-icon>
                  <div class="font-light text-base">Voltar ao topo</div>
                </router-link>
              </div>
            </nav>
          </div>
        </section>

        <div
          class="col-start-2 px-0 sm:pl-12 sm:pr-10 max-w-artist-card text-lg font-light tracking-wide leading-loose text-gray-700"
        >
          <section>
            <h1
              id="obra"
              class="font-head uppercase text-2xl font-medium text-gray-900 leading-6 tracking-wide"
            >
              Obra
            </h1>

            <p class="mt-6">
              Francimar é um artista Amazonense conhecido por suas pinturas de
              estilo único compostas por <strong>mandalas</strong>. Suas obras
              abordam principalmente temas como: a natureza, fauna e flora,
              lendas amazônicas e cultura indígena.
            </p>
          </section>

          <section class="mt-23">
            <h1
              id="biografia"
              class="font-head uppercase text-2xl font-medium text-gray-900 leading-6 tracking-wide"
            >
              Biografia
            </h1>

            <p class="mt-6">
              Francimar nasceu em 1964 na cidade de Manaus, Brasil, e aos
              <em>quatro anos</em> de idade já demonstrava seu dom artístico.
              Durante a juventude, trabalhou com publicidade, desenho técnico,
              serigrafia e ilustração. Em 1988, iniciou-se nas Artes Plásticas
              com o professor <strong>Anísio Mello</strong>, no
              <strong>Liceu de Artes Esther Mello</strong>.
            </p>
            <p class="mt-8">
              Desde então, já participou de mais de
              <em>70 exposições</em> individuais e coletivas, além de
              <em>11 premiações</em>. Atualmente, é registrado na AMAP -
              Associação Amazonense de Artistas Plásticos - e é Membro
              Efetivo-Fundador da ACL / AM - Academia Amazonense de Artes,
              Ciências e Letras.
            </p>
          </section>

          <section class="mt-23">
            <h1
              id="exposicoes"
              class="font-head uppercase text-2xl font-medium text-gray-900 leading-6 tracking-wide"
            >
              Exposições
            </h1>

            <div class="overflow-auto max-w-full">
              <table class="w-full table-auto mt-7" style="min-width: 600px;">
                <thead>
                  <tr>
                    <th
                      v-for="(field, i) in exhibitFields"
                      :key="i"
                      :class="field.class"
                    >
                      {{ field.head }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(exhibit, i) in exhibits.slice(0, exhibitCount)"
                    :key="i"
                  >
                    <td
                      v-for="(field, j) in exhibitFields"
                      :key="j"
                      :class="field.class"
                    >
                      {{ field.prop(exhibit) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="footer-panel">
              <button
                class="w-full uppercase font-head text-sm font-semibold py-3.5 tracking-wider text-gray-500"
                @click="
                  exhibitCount =
                    exhibitCount === exhibits.length ? 5 : exhibits.length
                "
              >
                Ver {{ exhibitCount != exhibits.length ? "mais" : "menos" }}
              </button>
            </div>
          </section>

          <section class="mt-23">
            <h1
              id="premiacoes"
              class="font-head uppercase text-2xl font-medium text-gray-900 leading-6 tracking-wide"
            >
              Premiações
            </h1>

            <div class="overflow-auto max-w-full">
              <table class="w-full table-auto mt-7" style="min-width: 600px;">
                <thead>
                  <tr>
                    <th v-for="(field, i) in awardFields" :key="i">
                      {{ field.head }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(award, i) in awards.slice(0, awardCount)" :key="i">
                    <td v-for="(field, j) in awardFields" :key="j">
                      {{ field.prop(award) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="footer-panel">
              <button
                class="w-full uppercase font-head text-sm font-semibold py-3.5 tracking-wider text-gray-500"
                @click="
                  awardCount = awardCount === awards.length ? 5 : awards.length
                "
              >
                Ver {{ awardCount != awards.length ? "mais" : "menos" }}
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiChevronUp, mdiMenuDown } from "@mdi/js";
import { exhibits } from "../data/exhibits";
import { collectiveExhibits } from "../data/collective-exhibits";
import { awards } from "../data/awards";

const exhibitTableFields = [
  {
    head: "Ano",
    prop: (obj) => obj.date,
  },
  {
    head: "Evento",
    prop: (obj) =>
      obj.name
        ? obj.name
        : `Exposição ${obj.type === "individual" ? "Individual" : "Coletiva"}`,
  },
  {
    head: "Espaço",
    prop: (obj) => obj.place,
  },
  {
    head: "Local",
    class: "w-1/5",
    prop: (obj) =>
      obj.country === "Brasil"
        ? `${obj.city} - ${obj.state}`
        : `${obj.city}, ${obj.country}`,
  },
];

const awardTableFields = [
  {
    head: "Ano",
    prop: (obj) => obj.date,
  },
  {
    head: "Evento",
    prop: (obj) => obj.event,
  },
  {
    head: "Prêmio",
    prop: (obj) => obj.award,
  },
  {
    head: "Local",
    prop: (obj) =>
      obj.country === "Brasil"
        ? `${obj.city} - ${obj.state}`
        : `${obj.city}, ${obj.country}`,
  },
];

export default {
  name: "Artist",
  props: {},
  data() {
    return {
      title: "Biografia - Francimar Barbosa",
      icons: {
        menu: mdiMenuDown,
        top: mdiChevronUp,
      },
      selectedTab: 0,
      exhibits: [
        ...exhibits.map((e) => {
          e.type = "individual";
          return e;
        }),
        ...collectiveExhibits.map((e) => {
          e.type = "collective";
          return e;
        }),
      ]
        .sort((a, b) => (a.date > b.date ? 1 : a.date == b.date ? 0 : -1))
        .reverse(),
      awards: [...awards],
      exhibitFields: exhibitTableFields,
      awardFields: awardTableFields,
      exhibitCount: 5,
      awardCount: 5,
    };
  },
};
</script>

<style lang="scss">
.max-w-artist-card {
  max-width: 934px;
}

#artist-banner {
  max-height: 280px;
}

table {
  thead tr {
    @apply border-b-2 border-gray-200;
  }
  tbody tr {
    @apply border-b border-gray-200;
  }
  th {
    @apply text-gray-900 uppercase text-left p-3.5 font-semibold font-head text-sm tracking-wider leading-tight;
  }
  td {
    @apply px-3 py-4 leading-tight text-base;
  }
}

.footer-panel {
  @apply rounded-b-md bg-gray-50;
}
</style>