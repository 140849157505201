<template>
  <div class="px-8 mb-36 lg:mr-4">
    <div class="max-w-gallery mx-auto sm:mx-auto font-head">
      <router-link
        :to="{ name: 'home' }"
        class="mt-6 inline-flex items-center text-gray-700"
      >
        <svg-icon
          :path="icons.back"
          type="mdi"
          class="text-gray-500 w-5 h-5"
        ></svg-icon>
        <span class="ml-1">Explorar galeria</span>
      </router-link>

      <div class="mt-12 mb-10">
        <h1
          class="
            uppercase
            text-2xl
            font-medium
            text-gray-900
            leading-6
            tracking-wide
            max-w-full
          "
        >
          <skeleton :showContent="series" skelClass="max-w-full w-80">
            <template #default> {{ series.nome }} </template>
          </skeleton>
        </h1>
        <hr class="mt-4 border-t-2" />
        <div
          class="
            flex
            items-center
            rounded-b-md
            px-4
            py-1
            font-body
            justify-between
            bg-gray-100
            text-gray-500
            tracking-wider
            text-sm
          "
        >
          <div :class="{ 'opacity-0': !workList }">
            <span v-if="workList && workList.length < totalCount">
              Mostrando
              <strong class="font-medium">{{ workList.length }}</strong> de
            </span>
            <strong class="font-medium">{{ totalCount }}</strong>
            obras
          </div>
          <select
            id="order-select"
            class="
              tracking-wider
              bg-gray-100
              px-2
              py-1
              hover:bg-gray-50 hover:cursor-pointer
            "
            v-model="sorting"
          >
            <option value="">Ordem padrão</option>
            <option value="titulo asc, id asc">Ordem alfabética</option>
            <option value="data desc, id asc">Mais recentes</option>
            <option value="data asc, id desc">Mais antigos</option>
          </select>
        </div>
      </div>

      <div
        v-for="(group, i) in groups"
        :key="i"
        class="grid gap-x-16"
        :class="{ 'mt-14': i > 0 }"
        :style="{
          'grid-template-columns': group
            .map((w) => w.weight.toString() + 'fr')
            .join(' '),
          'grid-template-rows':
            gridColumns === 1
              ? 'auto'
              : group.length === 1 && gridColumns < 6
              ? '32rem'
              : '20rem',
          'max-width':
            missingWeight(group) > 0 && i == groups.length - 1
              ? `calc(${(totalWeight(group) / gridColumns) * 100}% * 1.1)`
              : 'none',
        }"
      >
        <template v-for="(work, j) in group">
          <skeleton :showContent="work.id" :key="`${i},${j},1`">
            <router-link
              :to="{
                name: 'work',
                params: { slug: work.slug ? work.slug : 'nada' },
              }"
            >
              <async-image
                class="h-full"
                :src="
                  work.imagem_caminho
                    ? `${apiUrl}/images/work/${work.slug}_thumbnail.jpg`
                    : 'null'
                "
                :alt="`Foto da obra ${work.titulo}`"
                :imageClass="{
                  'w-full h-full object-cover rounded-sm shadow-xl': true,
                  'max-w-full max-h-full m-auto':
                    gridColumns === 1 && work.ratio == 0.5,
                }"
              />
            </router-link>
          </skeleton>

          <skeleton
            class="mt-3 row-start-2 min-w-0"
            :showContent="work.id"
            skelClass="h-14"
            :key="`${i},${j},2`"
          >
            <template #default>
              <div
                class="
                  bg-gray-100
                  border-l-2 border-r-2 border-gray-300
                  flex flex-col
                  items-center
                  py-2
                  px-3
                "
              >
                <h2
                  class="
                    text-gray-900 text-center
                    tracking-wide
                    text-sm
                    font-medium
                    truncate
                    max-w-full
                    uppercase
                  "
                >
                  {{ work.titulo }}
                </h2>
                <span class="text-gray-500 tracking-wider text-sm"
                  >{{ work.largura | dimension }} x
                  {{ work.altura | dimension }}</span
                >
              </div>
            </template>
          </skeleton>
        </template>
      </div>

      <hr class="mt-12 border-t-2" />

      <nav class="mt-4 flex justify-center gap-1.5 text-gray-600 font-body">
        <span class="mr-2">Página</span>
        <template v-for="(page, i) in reachablePages">
          <router-link
            :key="i"
            v-if="page"
            :to="{
              name: 'gallery',
              params: { page: page.toString(), seriesSlug },
            }"
            class="border border-gray-300 rounded-sm px-2 hover:bg-gray-100"
            :class="{ 'font-bold bg-gray-100': page == pageNumber }"
          >
            {{ page }}
          </router-link>
          <span :key="i" v-else>...</span>
        </template>
      </nav>
    </div>
  </div>
</template>

<script>
import { mdiArrowLeft, mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import axios from "axios";
import { bubblePartition as partition } from "@/utils/array-utils";

const placeholderWeights = [2, 2, 2, 1, 2, 2, 1, 3, 3];

function applyWeightRatio(work) {
  const ratio = work.largura / work.altura;
  if (Math.abs(1 - ratio) < 0.3) {
    work.weight = 2;
    work.grow = 1;
  } else {
    work.weight = work.largura > work.altura ? 3 : 1;
    work.grow = work.largura > work.altura ? 1.35 : 0.625;
  }
  work.grow = ratio;
  work.ratio = ratio;
}

export default {
  name: "Gallery",
  props: {},
  data() {
    return {
      title: "Mandalas de Francimar Barbosa",
      icons: {
        prev: mdiChevronLeft,
        next: mdiChevronRight,
        back: mdiArrowLeft,
      },
      pageNumber: 1,
      pageCount: 1,
      totalCount: null,
      gridColumns: 6,
      workList: null,
      groups: [],
      series: null,
      seriesSlug: "all",
      sorting: "",
    };
  },
  created() {
    if (this.$route.params["page"]) {
      this.pageNumber = parseInt(this.$route.params["page"]);
      this.seriesSlug = this.$route.params["series"];

      if (this.seriesSlug == "all" || this.seriesSlug == "others") {
        this.series = {
          nome: this.seriesSlug == "all" ? "Todas as obras" : "Outras obras",
          slug: this.seriesSlug,
        };
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "$responsive.breakpoint": {
      immediate: true,
      handler(newVal) {
        if (newVal === "xs") {
          this.gridColumns = 1;
        }
        if (newVal === "md") {
          this.gridColumns = 4;
        }
        if (newVal === "lg" || newVal === "xl") {
          this.gridColumns = 6;
        }
        this.createGroups();
      },
    },
    $route(to, from) {
      if (
        (to.name === "gallery" || to.name === "gallery-series") &&
        (from.name === "gallery" || from.name === "gallery-series")
      ) {
        this.fetchData();
      }
    },
    sorting: function (next, prev) {
      if (next !== prev) {
        this.fetchData();
      }
    },
  },
  methods: {
    fetchData() {
      this.groups = partition(
        6,
        placeholderWeights.map((w) => {
          return {
            weight: w,
            ratio: w / 2,
            grow: w,
          };
        }),
        (obj) => obj.weight
      );

      this.pageNumber = 1;
      if (this.$route.params["page"]) {
        this.pageNumber = parseInt(this.$route.params["page"]);
      }

      axios
        .get(
          `${this.apiUrl}/gallery/${this.pageNumber}?length=${18}&sort=${
            this.sorting
          }&series=${this.seriesSlug}`
        )
        .then((res) => {
          this.workList = res.data;
          if (this.seriesSlug !== "all") {
            this.series = {
              nome: this.workList[0].serie_nome,
              slug: this.seriesSlug,
            };
          }
          this.totalCount = res.headers["x-total-count"];
          const pageLength = res.headers["x-page-length"];
          this.pageCount = Math.ceil(this.totalCount / pageLength);
          this.createGroups();
          this.title =
            this.seriesSlug === "all"
              ? this.title
              : this.series.nome + " - Francimar Barbosa";

          this.$store.commit("setGalleryParams", {
            params: {
              series: this.series,
              page: this.pageNumber,
              sorting: this.sorting,
              pageCount: this.pageCount,
              totalCount: this.totalCount,
              pageLength,
            },
            content: this.workList,
          });
        });
    },
    createGroups() {
      if (!this.workList) return;
      const sourceArray = [
        ...this.workList.map((w) => {
          applyWeightRatio(w);
          if (this.gridColumns === 1) {
            w.weight = 1;
          }
          return w;
        }),
      ];
      if (this.gridColumns > 1) {
        this.groups = partition(
          this.gridColumns,
          sourceArray,
          (obj) => obj.weight
        );
      } else {
        this.groups = [...sourceArray].map((item) => [item]);
      }
    },
    totalWeight(group) {
      return group.map((w) => w.weight).reduce((a, b) => a + b);
    },
    missingWeight(group) {
      return this.gridColumns - this.totalWeight(group);
    },
  },
  computed: {
    reachablePages() {
      const page = this.pageNumber;
      const nextPage = page + 1;
      const prevPage = page - 1;
      const firstPage = 1;
      const lastPage = Math.max(this.pageCount, page);
      var pages = [];
      if (firstPage != page) pages.push(firstPage);
      if (prevPage > firstPage) {
        if (prevPage > firstPage + 1) pages.push(null);
        pages.push(prevPage);
      }
      pages.push(page);
      if (nextPage < lastPage) {
        pages.push(nextPage);
        if (nextPage < lastPage - 1) pages.push(null);
      }
      if (page != lastPage) pages.push(lastPage);
      return pages;
    },
  },
  filters: {
    dimension(val) {
      var text = Number(val).toFixed(2).toString();
      text =
        text.substr(text.length - 2, 2) === "00"
          ? Math.floor(Number(val))
          : text;
      return text + "m";
    },
  },
};
</script>

<style scoped lang="scss">
</style>
