<template>
  <div id="app">
    <portal-target name="modal-container"> </portal-target>
    <div
      class="relative z-10"
      :style="{
        'box-shadow': headerShadow ? '0px 2px 4px 0px rgba(0, 0, 0, 0.18)' : 'none',
      }"
    >
      <div class="px-4 md:px-8 lg:mr-4">
        <div
          class="
            font-head
            max-w-gallery
            mx-auto
            flex flex-col-reverse
            items-center
            sm:flex-row
          "
        >
          <nav class="flex items-start pt-2">
            <router-link
              class="
                -ml-3
                relative
                flex flex-col
                py-2
                px-3
                text-gray-500
                group
                hover:text-gray-900
                activated:text-gray-900
              "
              :to="{ name: 'home' }"
              active-class="activated"
            >
              Galeria
              <div
                class="
                  absolute
                  bottom-0
                  inset-x-0
                  w-full
                  h-1
                  group-hover:bg-green-300
                  group-activated:bg-green-300
                "
              ></div>
            </router-link>
            <router-link
              class="
                relative
                flex flex-col
                py-2
                px-3
                text-gray-500
                group
                hover:text-gray-900
                activated:text-gray-900
              "
              :to="{ name: 'artist' }"
              active-class="activated"
            >
              Artista
              <div
                class="
                  absolute
                  bottom-0
                  inset-x-0
                  w-full
                  h-1
                  group-hover:bg-green-300
                  group-activated:bg-green-300
                "
              ></div>
            </router-link>
            <router-link
              class="
                relative
                flex flex-col
                py-2
                px-3
                text-gray-500
                group
                hover:text-gray-900
                activated:text-gray-900
              "
              :to="{ name: 'contact' }"
              active-class="activated"
            >
              Contato
              <div
                class="
                  absolute
                  bottom-0
                  inset-x-0
                  w-full
                  h-1
                  group-hover:bg-green-300
                  group-activated:bg-green-300
                "
              ></div>
            </router-link>
          </nav>

          <router-link class="flex text-xl sm:ml-auto mt-3 sm:my-auto" :to="{ name: 'home' }">
            francimar
            <span class="ml-1 font-light">barbosa</span>
          </router-link>
        </div>
      </div>
    </div>

    <router-view></router-view>

    <div
      class="
        flex flex-col
        text-white
        py-2
        bg-green-500
        px-6
        font-light
        tracking-wide
        leading-5
      "
    >
      <div class="flex flex-col mx-auto relative">
        <div
          class="
            flex flex-col
            w-full
            sm:w-auto sm:flex-row
            flex-wrap
            justify-center
            mt-10
            gap-x-24 gap-y-6
            mb-8
            mx-auto
          "
        >
          <div>
            <h6 class="font-head font-semibold tracking-wider">MENU</h6>

            <nav class="flex flex-col mt-4 gap-4">
              <router-link class="px-1 -mx-1 hover:underline" :to="{ name: 'home' }"
                >Galeria</router-link
              >
              <router-link class="px-1 -mx-1 hover:underline" :to="{ name: 'artist' }"
                >Artista</router-link
              >
              <router-link class="px-1 -mx-1 hover:underline" :to="{ name: 'contact' }"
                >Contato</router-link
              >
            </nav>
          </div>

          <div class="max-w-full">
            <h6 class="font-head font-semibold tracking-wider">
              FRANCIMAR BARBOSA
            </h6>

            <nav class="flex flex-col mt-4 gap-4">
              <a
                class="flex gap-2 text-gray-100 items-center hover:underline"
                href="https://goo.gl/maps/M6bRnzfdkC1P85UEA"
                target="_blank"
                ><svg-icon type="mdi" class="h-4 w-4" :path="icons.mdiMapMarker" />Manaus, AM -
                Brasil</a
              >
              <a
                class="flex gap-2 text-gray-100 items-center hover:underline"
                href="mailto:francimar.barbosa@hotmail.com"
                ><svg-icon
                  type="mdi"
                  class="h-4 w-4"
                  :path="icons.mdiEmail"
                />francimar.barbosa@hotmail.com</a
              >
              <a
                class="flex gap-2 text-gray-100 items-center hover:underline"
                href="tel:+55-92-99902-2751"
                ><svg-icon type="mdi" class="h-4 w-4" :path="icons.mdiPhone" />+55 (92)
                99902-2751</a
              >
              <div class="flex items-center gap-2 text-gray-100">
                <hr class="flex-grow" />
                <a href="https://www.facebook.com/francimarbarbosaart" target="_blank"
                  ><svg-icon type="mdi" class="h-6 w-6" :path="icons.mdiFacebook"
                /></a>
                <a href="https://www.instagram.com/francimarbarbosaarte/"
                  ><svg-icon type="mdi" class="h-6 w-6" :path="icons.mdiInstagram"
                /></a>
                <hr class="flex-grow" />
              </div>
            </nav>
          </div>
        </div>

        <div class="mb-24 text-sm w-full text-center">
          <div class="absolute inset-x-0 bg-green-600 p-2 rounded">
            <p xmlns:cc="http://creativecommons.org/ns#">
              As imagens das obras de
              <a
                rel="cc:attributionURL dct:creator"
                property="cc:attributionName"
                href="http://francimarbarbosa.com"
                >Francimar Barbosa</a
              >
              estão sob a licença
              <a
                href="http://creativecommons.org/licenses/by-nc-nd/4.0/?ref=chooser-v1"
                target="_blank"
                rel="license noopener noreferrer"
                style="display: inline-block"
                >CC BY-NC-ND 4.0</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Gallery from "./components/Gallery.vue";
import Artist from "./components/Artist.vue";
import Contact from "./components/Contact.vue";
import Work from "./components/Work.vue";
import Home from "./components/Home.vue";
import VueRouter from "vue-router";
import { mdiMapMarker, mdiEmail, mdiFacebook, mdiInstagram, mdiPhone } from "@mdi/js";

const routes = [
  { path: "/", redirect: "/gallery" },
  { path: "/gallery", component: Home, name: "home" },
  {
    path: "/gallery/:series",
    redirect: "/gallery/:series/1",
    name: "gallery-series",
  },
  { path: "/gallery/work/:slug", component: Work, name: "work" },
  { path: "/gallery/:series/:page", component: Gallery, name: "gallery" },
  {
    path: "/artist",
    component: Artist,
    name: "artist",
    meta: { headerShadow: true },
  },
  { path: "/contact", component: Contact, name: "contact" },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
          offset: { x: 0, y: 24 },
        };
      }
      return { x: 0, y: 0, behavior: "smooth" };
    }
  },
});

export default {
  name: "App",
  router,
  data() {
    return {
      icons: {
        mdiMapMarker,
        mdiEmail,
        mdiFacebook,
        mdiInstagram,
        mdiPhone,
      },
      headerShadow: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.headerShadow = false;
        if (to.meta?.headerShadow) {
          this.headerShadow = to.meta.headerShadow;
        }
      },
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Quicksand";
  // Don't use local() here, since the local font might not have all weights.
  src: url(./assets/fonts/Quicksand-VariableFont_wght.ttf) format("truetype-variations");
  font-weight: 1 999;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto-Light.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: 700;
}

body {
  @apply bg-green-25;
}

.max-w-gallery {
  max-width: 1092px;
}

.tooltip {
  @apply bg-gray-800;
  @apply text-white;
  @apply px-2;
  @apply py-1;
  @apply leading-4;
  @apply rounded;
}

.modal-backdrop {
  background-color: #00000055;
  position: fixed;
  z-index: 900;
  @apply inset-0;
  @apply flex;

  & > * {
    touch-action: none;
  }
}

.modal {
  @apply bg-white;
  @apply mt-16;
  @apply mx-auto;
  @apply rounded-md;
  @apply p-4;
  max-width: 300px;
  z-index: 999;

  &.modal-dark {
    @apply bg-gray-900;
  }
}

.modal .close-button {
  @apply text-gray-700;
  @apply hover:bg-gray-200;
}

.modal.modal-dark .close-button {
  @apply text-gray-300;
  @apply bg-gray-900;
  &:hover {
    background-color: #333333aa;
  }
}
</style>
