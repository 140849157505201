<script>
import { API_URL } from "@/const.js";
import Vue from "vue";
import Vuex from "vuex";
import * as axios from "axios";

Vue.use(Vuex);

const defaultGalleryState = {
  page: 1,
  pageCount: 1,
  totalCount: 0,
  pageLength: 18,
  sorting: "data desc, id asc",
  series: {
    nome: "Todas as obras",
    slug: "all",
  },
};

export const mainStoreConfig = {
  state: {
    galleryParams: defaultGalleryState,
    workList: null,
    workIndex: -1,
    work: null,
  },
  mutations: {
    setGalleryParams(state, payload) {
      state.galleryParams = payload.params;
      state.workList = payload.content;
    },
    resetGallery(state) {
      state.galleryParams = defaultGalleryState;
      state.workList = null;
      state.workIndex = -1;
      state.work = null;
    },
    selectWork(state, index) {
      state.work = state.workList[index];
      state.workIndex = index;
    },
  },
  actions: {
    loadWork: async function(context, slug) {
      const index = context.state.workList ? context.getters.findWorkIndexBySlug(slug) : -1;
      if (index >= 0) {
        context.commit("selectWork", index);
        return context.state.work;
      } else {
        const work = (await axios.get(`${API_URL}/work/${slug}`)).data;
        context.commit("resetGallery");
        return work;
      }
    },
  },
  getters: {
    findWorkIndexBySlug: (state) => (slug) => {
      return state.workList.findIndex((val) => val.slug === slug);
    },
    nextWork: (state, getters) => {
      return state.workList && !getters.isLastWork ? state.workList[state.workIndex + 1] : null;
    },
    previousWork: (state, getters) => {
      return state.workList && !getters.isFirstWork ? state.workList[state.workIndex - 1] : null;
    },
    isLastWork: (state) => {
      return state.workIndex === state.pageLength - 1;
    },
    isFirstWork: (state) => {
      return state.workIndex === 0;
    },
    isLastPage: (state) => {
      return state.galleryParams.page === state.galleryParams.pageCount;
    },
    isFirstPage: (state) => {
      return state.galleryParams.page === 1;
    },
  },
};

export const store = new Vuex.Store(mainStoreConfig);

export default store;
</script>
