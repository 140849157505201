import { debounce } from "lodash";
import Vue from "vue";

const responsive = Vue.observable({
  breakpoints: {
    xs: true,
    sm: true,
    md: true,
    lg: true,
    xl: true,
  },
  breakpoint: "xs",
  setBreakpoint(val) {
    this.breakpoint = val;
    const keys = Object.keys(this.breakpoints);
    const index = keys.indexOf(this.breakpoint);
    keys.slice(0, index + 1).forEach((key) => (this.breakpoints[key] = true));
    keys.slice(index + 1).forEach((key) => (this.breakpoints[key] = false));
  },
});

export const breakpoints = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

export default {
  data() {
    return {
      breakpoints: {
        xs: true,
        sm: true,
        md: true,
        lg: true,
        xl: true,
      },
      breakpoint: "xs",
    };
  },
  created() {
    Vue.prototype.$responsive = responsive;
    window.addEventListener("resize", debounce(this.updateBreakpoint, 300));
  },
  destroyed() {
    window.removeEventListener("resize", this.updateBreakpoint);
  },
  mounted() {
    this.updateBreakpoint();
  },
  methods: {
    updateBreakpoint() {
      if (window.innerWidth >= breakpoints["xl"]) {
        this.breakpoint = "xl";
      } else if (window.innerWidth >= breakpoints["lg"]) {
        this.breakpoint = "lg";
      } else if (window.innerWidth >= breakpoints["md"]) {
        this.breakpoint = "md";
      } else if (window.innerWidth >= breakpoints["sm"]) {
        this.breakpoint = "sm";
      } else {
        this.breakpoint = "xs";
      }

      this.$responsive.setBreakpoint(this.breakpoint);
      this.breakpoints = this.$responsive.breakpoints;
    },
  },
};
