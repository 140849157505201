<template>
  <div class="flex mb-44 text-gray-900">
    <div class="flex flex-col mx-auto items-center text-center px-8">
      <h1 class="mt-28 text-2xl font-medium font-head tracking-wide uppercase">
        Contate Francimar
      </h1>

      <p
        class="mt-6 max-w-2xl text-lg font-light leading-9 tracking-wide text-gray-700"
      >
        Conecte-se com o artista para <strong>negociar valores</strong>,
        conhecer mais sobre <br class="hidden lg:inline" />
        as obras ou ainda <strong>solicitar encomendas</strong>. Siga-o nas
        redes para <br class="hidden lg:inline" />
        acompanhar <strong>novas obras</strong> e <strong>exposições</strong>.
      </p>

      <div class="flex flex-wrap gap-x-4 gap-y-6 mt-12 justify-center">
        <div class="flex flex-col items-center contact-card">
          <div class="flex rounded-full bg-green-300 w-24 h-24">
            <svg-icon
              class="m-auto w-8 h-8 text-white"
              type="mdi"
              :path="icons.mdiEmail"
            />
          </div>
          <span class="font-head font-semibold mt-2">Email</span>
          <a
            href="maito:francimar.barbosa@hotmail.com"
            class="flex flex-col items-center font-light"
          >
            <span class="relative">
              <span class="relative z-10">francimar.barbosa</span>
              <hr class="absolute inset-x-0 border-gray-500" style="bottom: 0.25rem">
            </span>
            <span class="relative text-sm text-gray-500">
              <span class="relative z-10">@hotmail.com</span>
              <hr class="absolute inset-x-0 border-gray-500" style="bottom: 0.125rem">
            </span>
          </a>
        </div>

        <div class="flex flex-col items-center contact-card">
          <div class="flex rounded-full bg-green-300 w-24 h-24">
            <svg-icon
              class="m-auto w-8 h-8 text-white"
              type="mdi"
              :path="icons.mdiPhone"
            />
          </div>
          <span class="font-head font-semibold mt-2">Fone</span>
          <a class="relative font-light" href="tel:+55-92-99902-2751">
            <span class="relative z-10">+55 (92) 99902-2751</span>
            <hr class="absolute inset-x-0 border-gray-500" style="bottom: 0.25rem">
          </a>
        </div>

        <div class="flex flex-col items-center contact-card">
          <div class="flex rounded-full bg-green-300 w-24 h-24">
            <svg-icon
              class="m-auto w-8 h-8 text-white"
              type="mdi"
              :path="icons.mdiFacebook"
            />
          </div>
          <span class="font-head font-semibold mt-2">Facebook</span>
          <a
            href="https://www.facebook.com/francimarbarbosaart"
            target="_blank"
            class="relative font-light"
          >
            <span class="relative z-10">Siga a Página</span>
            <hr class="absolute inset-x-0 border-gray-500" style="bottom: 0.25rem">
          </a>
        </div>

        <div class="flex flex-col items-center contact-card">
          <div class="flex rounded-full bg-green-300 w-24 h-24">
            <svg-icon
              class="m-auto w-8 h-8 text-white"
              type="mdi"
              :path="icons.mdiInstagram"
            />
          </div>
          <span class="font-head font-semibold mt-2">Instagram</span>
          <a
            href="https://www.instagram.com/francimarbarbosaarte/"
            class="relative font-light"
          >
            <span class="relative z-10">@francimarbarbosaarte</span>
            <hr class="absolute inset-x-0 border-gray-500" style="bottom: 0.25rem">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiEmail, mdiPhone, mdiInstagram, mdiFacebook } from "@mdi/js";

export default {
  data() {
    return {
      title: "Contato - Francimar Barbosa",
      icons: {
        mdiEmail,
        mdiPhone,
        mdiFacebook,
        mdiInstagram,
      },
    };
  },
};
</script>

<style>
.contact-card {
  @apply w-40;
}
</style>