<template>
  <div class="relative" data-cy="ratio-container">
    <div v-if="!disabled" class="ratio-span" :style="'--ratio:' + ratio"></div>
    <div v-if="!disabled" class="ratio-content absolute inset-0">
      <slot />
    </div>
    <slot v-if="disabled" />
  </div>
</template>

<script>
export default {
  props: {
    ratio: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.ratio-span {
  width: 100%;
  height: 0;
  padding-bottom: calc(100% / (var(--ratio)));
}
.ratio-content > * {
  @apply h-full;
}
</style>
