<script>
import { mdiClose } from "@mdi/js";
export default {
  props: {
    open: {
      default: false,
    },
    modalClass: {
      default: "",
    },
    closeButtonClass: {
      default: "",
    },
    modalStyle: {
      default: null,
    },
  },
  data() {
    return {
      icons: {
        close: mdiClose,
      },
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <portal to="modal-container" v-if="open">
    <div class="modal-backdrop" @click="close">
      <div class="modal" :class="modalClass" :style="modalStyle" @click.stop>
        <button
          class="
            inline-flex
            items-center
            rounded-full
            p-1
            close-button
            float-right
            ml-3
          "
          :class="closeButtonClass"
          @click="close"
        >
          <svg-icon
            class="m-auto h-full w-full"
            type="mdi"
            :path="icons.close"
          ></svg-icon>
        </button>

        <slot />
      </div>
    </div>
  </portal>
</template>