<template>
  <div>
    <img
      :class="[imageClass]"
      :style="[imageStyle]"
      v-bind="$props"
      @load="loadingSuccess"
      @error="loadingFailed"
      v-show="imageLoaded"
    />

    <div
      class="flex p-8 h-full w-full bg-gray-300"
      v-if="!imageLoaded || imageFailed"
      :class="[{ 'animate-pulse': !imageFailed }, imageClass]"
      :style="[imageStyle]"
    >
      <aspect-ratio class="m-auto max-w-full w-24" style="min-width: 1rem">
        <svg-icon
          class="w-full h-full text-white"
          v-if="imageFailed"
          type="mdi"
          :path="brokenIcon"
        ></svg-icon>
      </aspect-ratio>
    </div>
  </div>
</template>

<script>
import { mdiImageBrokenVariant, mdiSquareCircle } from "@mdi/js";
export default {
  name: "AsyncImage",
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: String,
    imageClass: null,
    imageStyle: null,
  },
  data() {
    return {
      imageFailed: false,
      imageLoaded: false,
      brokenIcon: mdiImageBrokenVariant,
      loadingIcon: mdiSquareCircle,
    };
  },
  created() {
    this.imageLoaded = false;
    this.imageFailed = false;
  },
  methods: {
    loadingFailed($event) {
      this.imageFailed = true;
      this.$emit("error", $event);
    },
    loadingSuccess($event) {
      this.imageLoaded = true;
      this.$emit("load", $event);
    },
  },
  watch: {
    src: function(cur, prev) {
      if (cur !== prev) {
        this.imageLoaded = false;
        this.imageFailed = false;
      }
    }
  },
};
</script>

<style>
</style>