<template>
  <div
    :class="{
      'bg-gray-300 animate-pulse-light': !showContent,
      [skelClass]: !showContent,
      [commonClass]: showContent,
    }"
  >
    <slot v-if="showContent" />
    <span v-else>&#8203;</span>
  </div>
</template>

<script>
// To lazily load the slot content, wrap the content around a <template #default/>
export default {
  props: {
    showContent: {
      required: true,
    },
    skelClass: {
      type: String,
      default: "",
    },
    commonClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pulse: false,
    };
  },
  watch: {
    showContent: function (newVal, oldVal) {
      if (newVal == oldVal) return;
      if (newVal) {
        this.delayedPulse();
      }
    },
  },
  mounted() {
    if (!this.showContent) {
      this.delayedPulse();
    }
  },
  methods: {
    delayedPulse() {
      new Promise((resolve) => setTimeout(resolve, 500)).then(() => {
        this.pulse = true;
      });
    },
  },
};
</script>