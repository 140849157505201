function getTitle(vm) {
    const { title } = vm
    if (title) {
        return Promise.resolve(typeof title === 'function'
            ? title.call(vm)
            : title)
    }
}
export default {
    created() {
        this.updateTitle();
    },
    watch: {
        title: function() {
            this.updateTitle();
        }
    },
    methods: {
        updateTitle() {
            const titlePromise = getTitle(this)
            if (titlePromise) {
                titlePromise.then((title) => {
                    document.title = title;
                })
            }
        },
        scrollToTop() {
            window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
        }
    }
}