exports.awards = [
    {
        "date": "2013",
        "event": "61º Salão de Artes Plásticas do Clube Militar",
        "award": "Medalha de Bronze",
        "city": "Rio de Janeiro",
        "state": "RJ",
        "country": "Brasil"
    },
    {
        "date": "2012",
        "event": "60º Salão de Artes Plásticas do Clube Militar",
        "award": "Medalha de Prata",
        "city": "Rio de Janeiro",
        "state": "RJ",
        "country": "Brasil"
    },
    {
        "date": "2010",
        "name": "Rituais da Floresta",
        "event": "Prêmio Microprojetos Mais Cultura p/ Amazônia Legal - FUNART / Ministério da cultura",
        "award": "Medalha de Prata",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2010",
        "event": "III Salão Doroteano de Artes Visuais",
        "award": "1° lugar",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2009",
        "event": "X Salão Manaus – Marinha do Brasil",
        "award": "3° lugar",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2007",
        "event": "VIII Salão Manaus – Marinha do Brasil",
        "award": "3° lugar",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2006",
        "event": "I Salão Tucuxi de Pintura",
        "place": "Galeria Palácio das Artes",
        "award": "2° e 3° lugares",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2004",
        "event": "5° Salão Curupira de Artes Plásticas",
        "award": "1° lugar",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2002",
        "event": "III Salão Manaus – Marinha do Brasil",
        "award": "1° lugar",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2000",
        "event": "I Salão Manaus – Marinha do Brasil",
        "award": "2° lugar",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1993",
        "event": "Encontro Artístico - Semana do Exército",
        "award": "3° lugar",
        "city": "Brasília",
        "state": "DF",
        "country": "Brasil"
    },
];