exports.exhibits = [
    {
        "date": "2017",
        "name": "Lendas e Rituais Amazônicos",
        "place": "Millenium Shopping",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2017",
        "name": "Remadas",
        "place": "Bubuia Restaurante",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2016",
        "name": "Cenas Urbanas",
        "event": "Comemoração ao Aniversário da Cidade de Manaus",
        "place": "Millenium Shopping",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2014",
        "name": "Artpop Amazônica",
        "place": "Shopping Ponta Negra",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2013",
        "name": "Cenas Urbanas",
        "place": "Palácio Rio Negro",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2012",
        "name": "Rituais da Floresta",
        "place": "ICBEU",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2010",
        "name": "I Virada Cultural da região norte",
        "place": "Amazonas Shopping",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2010",
        "name": "Cenas Urbanas",
        "place": "Artefacto - MSCasa",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2009",
        "name": "Lendas Amazônicas",
        "place": "Palácio da Justiça",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2008",
        "name": "Mandalas Pop Amazônicas",
        "place": "Galeria do Largo",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2008",
        "name": "Brincadeira de Criança",
        "place": "Millenium Shopping",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2008",
        "name": "Mandalas Amazônicas",
        "event": "Feira Internacional da Amazônia",
        "place": "Studio 5",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "2007",
        "name": "Brincadeira de Criança",
        "place": "Studio 5",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1996",
        "place": "Rio Negro Center",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1996",
        "place": "Restaurante Fiorentina - Amazonas Shopping",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1996",
        "place": "Banco do Brasil",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1996",
        "place": "Hotel Tropical",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1996",
        "place": "BANESPA",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1995",
        "place": "Banco Itaú - Floriano Peixoto",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1995",
        "place": "Restaurante Fiorentina - Amazonas Shopping",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1995",
        "name": "Paisagens Amazônicas",
        "place": "Amazonas Shopping",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1995",
        "place": "Caixa Econômica Federal - Amazonas Shopping",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1995",
        "place": "Amazonas Shopping",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1995",
        "place": "Shopping Iguatemi",
        "city": "Belém",
        "state": "PA",
        "country": "Brasil"
    },
    {
        "date": "1994",
        "name": "Exposição do Liceu de Artes e Ofício Cláudio Santoro",
        "place": "Amazonas Shopping",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1994",
        "place": "Banco do Brasil",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1994",
        "place": "Amazonas Shopping",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
    {
        "date": "1994",
        "place": "Shopping Castanheira",
        "city": "Belém",
        "state": "PA",
        "country": "Brasil"
    },
    {
        "date": "1993",
        "name": "Paisagens Amazônicas",
        "place": "Amazonas Shopping",
        "city": "Manaus",
        "state": "AM",
        "country": "Brasil"
    },
];