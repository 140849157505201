import "./setup.js";

import Vue from "vue";
import App from "./App.vue";
import store from "./stores/MainStore";

new Vue({
  render: (h) => h(App),
  store: store,
}).$mount("#app");
